
.wrap
  width 100vw
  height 100vh
  overflow hidden
  background-color #F2F2F2
.autoWidth
  width calc(100% - 24px)
input
  margin 49px 12px 9px
  height 30px
  text-indent 34px
  caret-color #28B999
  color #333
  border 1px solid #28B999
  border-radius 1px
  background-size:  16px
  background-repeat: no-repeat
  background-position: 10px
  background-image url('../../assets/Shape.png')
.inputRight
  position absolute
  bottom 14px
  right 20px
  font-size 14px
  color #28B999
  display inline-block
  & span:nth-of-type(1)
    display inline-block
    margin-right 4px
    width 14px
    height 14px
    vertical-align middle
    background-size:  12px
    background-repeat: no-repeat
    background-image url('../../assets/clearIcon.png')
.list
  padding 0 12px
  height 52px
  line-height 52px
  text-indent 26px
  display flex
  justify-content space-between
  align-items center
  border-top 1px solid #EEEEEE
  border-bottom 1px solid #EEEEEE
  font-size 17px
  background-color #fff
  background-size 16px
  background-repeat no-repeat
  background-position 12px
  background-image url('../../assets/s.png')
  & .next
    display inline-block
    width 18px
    height 18px
    vertical-align text-top
    background-size contain
    background-image url('../../assets/next.png')
.searchNum
  padding-left 16px
  height 43.5px
  line-height 43.5px
  font-size 15px
  color #33333
 .contentList
    padding 12px 16px
    height 100%
    background-color #fff
    & .line
      margin-bottom 10px
      font-size 17px
    & .between
      display flex
      justify-content space-between
      align-items center
      & span:nth-of-type(1)
        font-size 14px
        color #999999
      & span:nth-of-type(2)
        width 62px
        height 24px
        font-size 13px
        color #FFFFFF
        text-align center
        line-height 24px
        border-radius 16px
        background-image linear-gradient(135deg, #87E8DE 0%, #13C2C2 100%)
.loading
  margin-top 10%vh
  position absolute
  text-align center
